import WebServiceRequest from './WebServiceRequest'

class PageCreateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Page/Create')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}

class PageUpdateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Page/Update')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}

class PageGetByIdRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Page/GetById')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}

class PageDeleteRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Page/Delete')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}

class PageGetListRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Page/GetList')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}

class PageGetByIdForWebRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Page/GetByIdForWeb')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}

class PageGetByUrlForWebRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Page/GetByUrlForWeb')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}

export {
    PageCreateRequest,
    PageUpdateRequest,
    PageGetByIdRequest,
    PageDeleteRequest,
    PageGetListRequest,
    PageGetByIdForWebRequest,
    PageGetByUrlForWebRequest
}